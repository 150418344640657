<template>
    <table dir="ltr" class="m-auto">
        <tr>
            <td colspan="3" style="text-align:center">{{ $t("search-result.return-date") }}</td>
            <td></td>
            <td colspan="3" style="text-align:center">{{ $t("search-result.output-date") }}</td>
        </tr>
        <tr>
            <td class="able-click" @click="chooseNextArrival" :class="{ 'disabled' : !ableGotoNextArrival }">
                <i class="fa fa-caret-left" />
            </td>
            <td ><span style="display:none">{{arrivalDate}}</span> {{arrivalDateIL}} </td>
            <td class="able-click" @click="choosePrevArrival" :class="{ 'disabled' : !ableGotoPrevArrival }">
                <i class="fa fa-caret-right" />
            </td>
            <td></td>
            <td class="able-click" @click="chooseNextDeparture" :class="{ 'disabled' : !ableGotoNextDeparture }">
                <i class="fa fa-caret-left" />
            </td>
            <td ><span style="display:none">{{departureDate}}</span> {{departureDateIL}} </td>
            <td class="able-click" @click="choosePrevDeparture" :class="{ 'disabled' : !ableGotoPrevDeparture }">
                <i class="fa fa-caret-right" />
            </td>
        </tr>
    </table>

</template>
<script>
import { mapGetters } from 'vuex';
import gMixin from '@/utils/mixins';

export default {
  name: 'SearchPrevNextDateFright',
  mixins: [gMixin],
  data() {
    return {
      currentDepartureNumber: 0,
      currnetArrivalNumber: 0,
    };
  },
  computed: {
    ...mapGetters({
      category: 'GET_CURRENT_CATEGORY',
      isLoading: 'GET_LOADING_STATE',
      calendarData: 'GET_CALENDAR_DATA',
    }),
    query() {
      const { query } = this.$route;
      return query;
    },
    dest() {
      const { dest } = this.query;
      return dest;
    },
    fromDate() {
      const { fromDate } = this.query;
      return fromDate;
    },
    toDate() {
      const { toDate } = this.query;
      return toDate;
    },
    availableDateList() {
      const { dest, calendarData } = this;
      const { subjectCodes } = this.category;

      let result = [];
      const categorySubjectIdList = subjectCodes.map(
        (item) => item,
      );

      if (calendarData) {
        result = calendarData.filter(
          (rec) => rec.dest === dest && categorySubjectIdList.includes(rec.sub),
        );
        result = Object.entries(this.groupByKey(result, 'date'));
      }
      result.sort((a, b) => (a[0] < b[0] ? -1 : 1));
      return result;
    },
    departureDate() {
      const ableDateList = this.availableDateList;
      if (ableDateList.length === 0) return '';
      return ableDateList[this.currentDepartureNumber][0];
    },
    arrivalDate() {
      const ableDateList = this.availableDateList;
      if (ableDateList.length === 0) return '';
      const duration = ableDateList[this.currentDepartureNumber][1][this.currnetArrivalNumber].dur[0];
      return this.dateFormat(this.addDays(this.departureDate, duration));
    },
    departureDateIL() {
      const ableDateList = this.availableDateList;
      if (ableDateList.length === 0) return '';
      return formatDateIL(ableDateList[this.currentDepartureNumber][0]);
    },
    arrivalDateIL() {
      const ableDateList = this.availableDateList;
      if (ableDateList.length === 0) return '';
      const duration = ableDateList[this.currentDepartureNumber][1][this.currnetArrivalNumber].dur[0];
      return formatDateIL(this.dateFormat(this.addDays(this.departureDate, duration)));
    },
    ableGotoNextDeparture() {
      const ableDateListLength = this.availableDateList.length;
      if (ableDateListLength === 0) return false;
      return !this.isLoading && this.currentDepartureNumber < ableDateListLength - 1;
    },
    ableGotoPrevDeparture() {
      return !this.isLoading && this.currentDepartureNumber !== 0;
    },
    ableGotoNextArrival() {
      const ableDateList = this.availableDateList;
      if (ableDateList.length === 0) return false;
      const arrivalDateListLength = ableDateList[this.currentDepartureNumber][1].length;
      return !this.isLoading && this.currnetArrivalNumber < arrivalDateListLength - 1;
    },
    ableGotoPrevArrival() {
      return !this.isLoading && this.currnetArrivalNumber !== 0;
    },
  },
  watch: {
    availableDateList() {
      this.setInitNumber();
    },
  },
  mounted() {
    this.setInitNumber();
  },
  methods: {
    setInitNumber() {
      if (this.availableDateList.length !== 0) {
        this.currentDepartureNumber = this.availableDateList.findIndex((item) => item[0] === this.fromDate);
        if (this.currentDepartureNumber < 0) this.currentDepartureNumber = 0;
        this.currnetArrivalNumber = this.availableDateList[this.currentDepartureNumber][1].findIndex((item) => this.dateFormat(this.addDays(item.date, item.dur[0])) === this.toDate);
        if (this.currnetArrivalNumber < 0) this.currnetArrivalNumber = 0;
      }
    },
    chooseNextDeparture() {
      if (this.ableGotoNextDeparture) {
        this.currentDepartureNumber += 1;
        this.currnetArrivalNumber = 0;
        this.updatedSearchDate();
      }
    },
    choosePrevDeparture() {
      if (this.ableGotoPrevDeparture) {
        this.currentDepartureNumber -= 1;
        this.currnetArrivalNumber = 0;
        this.updatedSearchDate();
      }
    },
    chooseNextArrival() {
      if (this.ableGotoNextArrival) {
        this.currnetArrivalNumber += 1;
        this.updatedSearchDate();
      }
    },
    choosePrevArrival() {
      if (this.ableGotoPrevArrival) {
        this.currnetArrivalNumber -= 1;
        this.updatedSearchDate();
      }
    },
    updatedSearchDate() {
      const body = {
        ...this.query,
        fromDate: this.departureDate,
        toDate: this.arrivalDate,
        categoryId: this.category.code,
        // categoryId: 'Flight_Only',
      };
      this.$router.push({ path: '/search-result', query: body });
    },
  },
};

function formatDateIL(input) {
  const datePart = input.match(/\d+/g), year = datePart[0], month = datePart[1], day = datePart[2];
  return `${day}/${month}/${year}`;
}
</script>
<style scoped>
  td {
    padding: 0 10px;
  }
  .able-click:not(.disabled) {
    cursor: pointer;
  }
  .able-click:not(.disabled):hover {
    transform: scale(1.5);
  }
  .able-click:not(.disabled):active {
    transform: scale(1.2);
  }
  .able-click.disabled {
    opacity: 0.5;
  }
</style>
